import React from 'react'

export default function Setting2() {
  return (
    <div className="c-content">
      <div className="c-content-header">
        <h4>Setting 2</h4>
        <p>Overview</p>
      </div>
    </div>
  )
}
